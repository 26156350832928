import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


export const routes: Routes = [
  { // node request form filed
    path: 'formobject', //component: FormobjectComponent,
    loadComponent: () => import('./formobject/formobject.component').then(m => m.FormobjectComponent)
  },
  { // node request hmtl5 to video
    path: 'videorequestobject', // component: VideorequestobjectComponent,
    loadComponent: () => import('./videorequestobject.component').then(m => m.VideorequestobjectComponent)
  },
  { // node request hmtl5 to video
    path: '', // component: VideorequestobjectComponent,
    loadComponent: () => import('./videorequestobject.component').then(m => m.VideorequestobjectComponent)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

//export const routedComponents = [AppComponent];
