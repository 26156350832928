/// <reference types="@angular/localize" />

import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';

import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
import { RouterModule } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from './app/app.component';
import { importProvidersFrom } from '@angular/core';
import { FormsendsControllerService } from './app/shared/api/services';
import { ApiModule } from './app/shared/api/api.module';
import { BASE_URL } from './app/shared/base.api';


 
bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            ApiModule.forRoot({ rootUrl: BASE_URL }), 
            FormsendsControllerService,
            BrowserModule, 
            AppRoutingModule, RouterModule),
        provideHttpClient(withFetch(), withInterceptorsFromDi()),
        provideAnimations(),
    ]
})
    .catch(err => console.error(err));
